import React, { useState, useRef, useEffect } from 'react';

import useOutsideClick from '../../hooks/useOutsideClick'
import IconArrow from '../svg/commons/arrow_head';
import "../../styles/_components/_comparator/_dropdown.scss";

const Dropdown = ({items, default_item = "name item", handleItemClick, _className="", type=1, label=false, labelName="", isForm=false}) => {
    const [isActive, setActive] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, setActive);

    //useEffect for add and remove class for z-index=1
    useEffect(()=>{
        if(isActive){
            wrapperRef.current.classList.add("o-dropdown__active");
        }else{
            wrapperRef.current.classList.remove("o-dropdown__active");
        }
    },[isActive])

    function handleClick(version) {
        isForm ? handleItemClick(version) : handleItemClick(version.uid);
        setActive(false);
    }

    function getItems() {
        return (
            <div className="o-dropdown__list">
                <div className="o-dropdown__list--wrapper" onClick={() => setActive(!isActive)}>
                    <ul>
                        {
                            items.map(item => {
                                return(
                                    <li key={item.uid}>
                                        <span>{item.name}</span>
                                        {item.versions.map(version => <p key={version.uid} onClick={() => handleClick(version)}>{version.data.title.text}</p>)}
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        )
    }

    return (
        <div className="o-dropdown" ref={wrapperRef}>
            { label && <label>{labelName}</label>}
            <div className={`o-dropdown__button ${_className}`} onClick={() => setActive(!isActive)} >
                <p>{default_item}</p>
                <div className="icon__arrow">
                    <IconArrow />
                </div>
            </div>
            {
                isActive && getItems()
            }
        </div>
    );
};

export default Dropdown;