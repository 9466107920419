import React from 'react';

export const ComparatorRowBlock = ({title, titleActive, items, blockClass=""}) => {
    return (
        <div className={`comparator__row--block ${blockClass}`}>
            {titleActive && <h2 className="comparator__row--block__title">{title}</h2>}
            <ul className="comparator__row--block__container">
                {items.map((item, i) => (
                    <li key={i}>
                        {/* {"summary_img" in item ?  <img src={item.summary_img.url} alt=""/> : null} */}
                        <div dangerouslySetInnerHTML={{__html: item.description.html}}></div>
                        {"value" in item ?  <p className="description">{item.value.text}</p> : null}
                    </li>
                ))}
            </ul>
        </div>
    );
}
