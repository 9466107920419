import React from 'react'

const SvgFloodlight = props => (
    <svg viewBox="0 0 100 100" {...props}>
        <path d="M50.3 11.3c1 0 1.8-.8 1.8-1.8V4.6c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8v4.9c0 1 .8 1.8 1.8 1.8M15.2 41.6h4.9c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8h-4.9c-1 0-1.8.8-1.8 1.8-.1.9.7 1.8 1.8 1.8M58.8 43.4c0-1-.8-1.8-1.8-1.8H43.7c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8h4.8v4.2c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8v-4.2h4.8c1.1.1 1.9-.7 1.9-1.8M27.6 19.6c.4.4.8.5 1.3.5s.9-.2 1.3-.5c.7-.7.7-1.9 0-2.6l-3.5-3.5c-.7-.7-1.9-.7-2.6 0-.7.7-.7 1.9 0 2.6l3.5 3.5zM24.2 67.7c.5 0 .9-.2 1.3-.5l3.5-3.5c.7-.7.7-1.9 0-2.6-.7-.7-1.9-.7-2.6 0l-3.5 3.5c-.7.7-.7 1.9 0 2.6.3.3.8.5 1.3.5M71.7 20.2c.5 0 .9-.2 1.3-.5l3.5-3.5c.7-.7.7-1.9 0-2.6-.7-.7-1.9-.7-2.6 0L70.4 17c-.7.7-.7 1.9 0 2.6.4.4.8.6 1.3.6M71.7 61.1c-.7.7-.7 1.9 0 2.6l3.5 3.5c.4.4.8.5 1.3.5s.9-.2 1.3-.5c.7-.7.7-1.9 0-2.6l-3.5-3.5c-.7-.8-1.9-.8-2.6 0M80.6 41.6h4.9c1 0 1.8-.8 1.8-1.8s-.8-1.8-1.8-1.8h-4.9c-1 0-1.8.8-1.8 1.8-.1.9.7 1.8 1.8 1.8" />
        <path fill="none" d="M13.3 97.3V2.7h74v94.6" />
        <path d="M50.3 14.6h-.9-.1c-.6 0-1.3.1-1.9.2-.3 0-.7.1-1 .1-.6.1-1.3.2-1.9.4h-.1c-.6.1-1.1.3-1.7.5-.1 0-.2.1-.3.1-.2.1-.5.2-.7.2-.1 0-.3.1-.4.2-.1 0-.2.1-.3.1-4.8 1.9-9 5.3-11.8 9.8-.1.1-.2.3-.3.4-.1.2-.3.4-.4.6v.1c-2.1 3.7-3.3 7.9-3.3 12.4 0 10.3 4.7 16.6 8.1 21.1 2.1 2.8 3.7 4.9 3.7 7 0 .9 0 1.7.1 2.6V86.3c.6 3 2.3 5.7 4.6 7.7 2.4 2 5.4 3.1 8.5 3.1 3.1 0 6.1-1.1 8.5-3.1 2.3-2 4-4.7 4.6-7.7v-.2-15.6c.1-.8.1-1.7.1-2.6 0-2.1 1.6-4.3 3.7-7 3.4-4.6 8.1-10.8 8.1-21.1.2-13.9-11-25.2-24.9-25.2zm0 79c-3.8 0-7.1-2.3-8.7-5.6h17.5c-1.6 3.3-5 5.6-8.8 5.6zm9.5-9.3H40.9v-4.2h18.9v4.2zm0-7.9H40.9v-4.2h18.9v4.2zm4.5-17.8c-2.4 3.2-4.4 5.9-4.4 9.3v.7h-7.7v-4.2c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8v4.2h-7.7v-.7c0-3.3-2.1-6.1-4.4-9.3-2.4-3.2-5.2-6.9-6.5-12.1v-.1c-.5-2-.8-4.2-.8-6.7 0-2.3.4-4.6 1.1-6.7.1-.4.3-.8.4-1.2.4-1 .9-2 1.4-2.9l.1-.1c.1-.1.2-.3.2-.4 2.3-3.7 5.7-6.6 9.8-8.4.1 0 .1-.1.2-.1.2-.1.4-.1.5-.2.1-.1.3-.1.4-.2.1 0 .2-.1.2-.1.4-.2.9-.3 1.4-.4h.1c.5-.1 1-.3 1.5-.3.5-.1 1-.2 1.6-.2.5-.1 1-.1 1.6-.1h.9C62.4 18.4 72 28 72 39.8c-.3 9-4.4 14.5-7.7 18.8z" />
    </svg>
)

export default SvgFloodlight;