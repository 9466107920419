import React,{useEffect} from "react"

import Layout from "../components/layout"
import { ComparatorContainer } from "../content/comparator/_comparator_container"
import SvgFloodlight from "../components/svg/comparator/floodlight"
import Head from '../components/head'
import { TitleStroke } from "../components/_titles/_header_title_stroke"
import { ElasticHeader } from "../components/headerBgCurve/elastic_header"
import "../styles/pages/comparator/_comparator.scss"

const Comparator = () => {

    let dropdown1, dropdown2, dropdown3, top, maxTopScroll, fade, dropdownFake1, dropdownFake2, dropdownFake3;
    let isfound = false;

    useEffect(() => {

        const wWidth = window.innerWidth;

        if( wWidth < 768){
            maxTopScroll = 80;
        } else if(wWidth < 1024){
            maxTopScroll = 138;
        } else if(wWidth < 1920){
            maxTopScroll = 142;
        }else{
            maxTopScroll = 170;
        }

        window.addEventListener('scroll', handleScroll);
        document.getElementById('header__container').classList.add('comparator__header')
        return () => {
            window.removeEventListener("scroll", handleScroll);
            document.getElementById('header__container').classList.remove('comparator__header')
        };
    }, [])

    function handleScroll(){

        let scrollY = window.pageYOffset;

        if (!isfound){
            dropdown1 = document.querySelectorAll('.o-dropdown')[0];
            dropdown2 = document.querySelectorAll('.o-dropdown')[1];
            dropdown3 = document.querySelectorAll('.o-dropdown')[2];
            fade = document.querySelector('.container__fade');
            dropdownFake1 = document.querySelectorAll('.comparator__dropdown--fake')[0];
            dropdownFake2 = document.querySelectorAll('.comparator__dropdown--fake')[1];
            dropdownFake3 = document.querySelectorAll('.comparator__dropdown--fake')[2];

            top = dropdown1.offsetTop - maxTopScroll;
            isfound = true;
        }

        if( scrollY >= top ){
            dropdown1.classList.add("sticky");
            dropdown2.classList.add("sticky");
            dropdownFake1.classList.add("is-visible");
            dropdownFake2.classList.add("is-visible");
            if(dropdown3 !== undefined){
                dropdown3.classList.add("sticky");
                dropdownFake3.classList.add("is-visible");
            }
            fade.classList.add("fadeout");
        }else{
            dropdown1.classList.remove("sticky");
            dropdown2.classList.remove("sticky");
            dropdownFake1.classList.remove("is-visible");
            dropdownFake2.classList.remove("is-visible");
            if(dropdown3 !== undefined){
                dropdown3.classList.remove("sticky");
                dropdownFake3.classList.remove("is-visible");
            }
            fade.classList.remove("fadeout");
        }
    }

    let metas= {
        title:'Cinsa Comparador',
        description: 'Cinsa Comparador',
        image:''
    }

    return (
        <Layout header_visible={true}>
            <div className="comparator">
                <div className={`e-header_bg`}>
                    <TitleStroke _className='center' textStroke="Comparador" text="cinsa" colorStroke="#001f5b" colorText="#001f5b" />
                </div>
                <div className="container__fade"></div>
                <ComparatorContainer />
            </div>
            <div className="comparator__footer">
                <span className='flood__light'>
                    <SvgFloodlight fill='#375ADE' />
                </span>
                <ul className="comparator__footer--description">
                    <li>Un servicio equivale a una regadera de 5 l/min.</li>
                    <li>Medio servicio equivale a un lavabo de 3 l/min.</li>
                    <li>Una tina equivale a 150L.</li>
                    <li>Se recomienda instalar el calentador lo más cerca de la regadera.</li>
                    <li>Modelos disponibles en gas lp o gas natural, las medidas de altura y diámetro son nominales.</li>
                </ul>
            </div>
        </Layout>
    )
}

export default Comparator
