import React, { useState } from 'react';

import Dropdown from "../../components/_comparator/dropdown_custom";
import { ComparatorRowBlock } from './_comparator_row_block';
import ButtonSquareForms from '../../components/buttons/_button_square_forms';

export const ComparatorRow = ({allData, data, item, titleActive = false, classNew=""}) => {

    const [filterItem, setFilterItem] = useState(item);
    const [filterIdeal, serFilterIdeal] = useState( getDataIdeal(item) );
    const [filterLink, setFilterLink] = useState( getLinkTechnical(item) );

    function changeItem(itemId) {
        const newFilterItem = allData.filter(item => item.uid === itemId );
        setFilterItem(newFilterItem[0])
        serFilterIdeal(getDataIdeal(newFilterItem[0]));
        setFilterLink(getLinkTechnical(newFilterItem[0]));
    }

    function getDataIdeal(dataItem){
        return data.filter(ideal => ideal.uid === dataItem.data.model.uid )[0].dataIdeal;
    }

    function getLinkTechnical(dataItem){
        return data.filter(ideal => ideal.uid === dataItem.data.model.uid )[0].link_technical;
    }

    return (
        <div className={`comparator__row ${classNew}`}>
            <div className="comparator__dropdown--fake"></div>
            <Dropdown items={data} default_item={filterItem.data.title.text} handleItemClick={changeItem}/>
            <div className="comparator__container--row">
                <img className="comparator__row--image" src={filterItem.data.image.url} alt={filterItem.data.title.text}/>

                <ComparatorRowBlock
                blockClass="comparator__row--idealUses"
                title={"Usos ideales"}
                titleActive={titleActive}
                items={filterIdeal}/>

                <ComparatorRowBlock
                    blockClass="comparator__row--dimensions"
                    title={filterItem.data.body[0].primary.block_title}
                    titleActive={titleActive}
                    items={filterItem.data.body[0].items}/>

                {/* {
                    filterItem.data.body.map((item, index) => {
                        if(item.__typename === "PrismicVersionBodyComparator") {
                            let _class = index === 1 ? "comparator__row--efficiency" : "comparator__row--datasheet";
                            return <ComparatorRowBlock
                                blockClass={_class}
                                title={item.primary.title1}
                                titleActive={titleActive}
                                items={item.items}
                                key={index}/>
                        }
                    })
                } */}
                { filterLink!==null ?
                    <ButtonSquareForms text="Ficha técnica" background="41, 119, 188" href={filterLink} _target="_blank" />
                    : null
                }
            </div>
        </div>
    )
}