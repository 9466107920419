import React, { useEffect, useState, Fragment } from "react";

import "../../styles/pages/comparator/_comparator.scss";
import { ComparatorRow } from "./_comparator_row";
import { useCurrenDevice } from "../../hooks/useCurrentDevice";
import { usePrismVersions } from '../../hooks/use_prism-versions';
import { usePrismModels } from '../../hooks/use_prism-models';
import { usePrismCategories } from '../../hooks/use_prims_categories';

export const ComparatorContainer = () => {
    const [device] = useCurrenDevice();
    const [versions, setVersions] = useState(null)
    const data = usePrismVersions().nodes;
    const models = usePrismModels().nodes;
    const categories = usePrismCategories().nodes;

    useEffect(() => {
        let orderModels = [];
        categories.map(category => {
            models.filter(model => model.data.category.uid === category.uid)
                .map(model => {
                    const versions_array = data.filter(version => version.data.model.document[0].data.name.text === model.data.name.text);
                    versions_array.sort(function(a, b) {
                        return a.data.sort_index - b.data.sort_index;
                    });

                    let dataIdeal;
                    let link_technical;

                    if (model.data.body == null) return;

                    model.data.body.map(element =>{
                        if(element.__typename === "PrismicModelBodyUsosIdeales"){
                            dataIdeal = element.items;
                        }
                        if(element.__typename === "PrismicModelBodyArchivos"){
                            link_technical = element.primary.technical.url
                        }
                    });
                    orderModels = [...orderModels, {
                        name: model.data.name.text,
                        uid: model.uid,
                        versions: versions_array,
                        dataIdeal: dataIdeal,
                        link_technical: link_technical
                    }];
                })
        })
        setVersions(orderModels);
    }, [])

    return (
        <div className="comparator__container">
            {
                versions &&
                <Fragment>
                    <ComparatorRow allData={data} data={versions} item={versions[0].versions[0]} titleActive={true} classNew="comparator__row--1" />
                    <ComparatorRow allData={data} data={versions} item={versions[1].versions[0]} classNew="comparator__row--2"/>
                    {device == "desktop" ? <ComparatorRow allData={data} data={versions} item={versions[2].versions[0]} classNew="comparator__row--3"/> : null}
                </Fragment>
            }
        </div>
    );
};


