import { useStaticQuery, graphql } from "gatsby";

export const usePrismVersions = () => {
    const prismicVersions = useStaticQuery(
        graphql`
            query {
            allPrismicVersion{
                nodes {
                    uid
                    data {
                        sort_index
                        title {
                            text
                        }
                        image {
                            url
                        }
                        model {
                            uid
                            document {
                                data{
                                category {
                                    document {
                                    data {
                                        name{
                                        text
                                        }
                                    }
                                    }
                                }
                                name {
                                    text
                                }
                                }
                            }
                        }
                        body {
                            __typename
                            ...on PrismicVersionBodySummary {
                                primary {
                                    block_title
                                    bottom_text {
                                        html
                                    }
                                }
                                items {
                                    summary_img {
                                        url
                                    }
                                    description {
                                        html
                                    }
                                }
                            }
                            ...on PrismicVersionBodyComparator {
                                primary {
                                    title1
                                }
                                items {
                                    description {
                                        html
                                    }
                                }
                            }
                        }
                    }
                }
            }
            }
        `
    )
    return prismicVersions.allPrismicVersion;
}