import React, { useRef } from "react"
import "../../styles/_components/_buttons/_button_square_forms.scss"

const  ButtonSquareForms = ({text, background, href, _target, disabled=false}) => {

    const backgroundColor = useRef(null)
    return (
        href != undefined ?
            <a ref={backgroundColor} className="c-button_submit" href={ href } target={_target}
            style={disabled ? {backgroundColor: `rgba(${background}, 0.8)`} : {backgroundColor: `rgb(${background})`}}
            onMouseEnter={()=> backgroundColor.current.style.backgroundColor = `rgba(${background}, 0.8)`}
            onMouseOut={()=>backgroundColor.current.style.backgroundColor = `rgb(${background})` }>
                {text}
            </a>
            :
            <button ref={backgroundColor} className={disabled ? "c-button_submit c-button_noSubmit" : "c-button_submit"} type={disabled ? "button" : "submit"}
            style={ {backgroundColor: `rgb(${background})`}}
            onMouseEnter={()=> disabled ? backgroundColor.current.style.backgroundColor = `rgb(${background})` : backgroundColor.current.style.backgroundColor = `rgba(${background}, 0.8)`}
            onMouseOut={()=>backgroundColor.current.style.backgroundColor = `rgb(${background})` }>
                {text}
            </button>
    )
}

export default ButtonSquareForms